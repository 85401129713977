import React from 'react';
import style from './vector.module.scss';

export const GiHubIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.712 25.715"
      className={style._git_icon}
    >
      <path
        d="M8.6,21.268c0,.106-.119.191-.27.191-.171.016-.29-.069-.29-.191,0-.106.119-.191.27-.191C8.465,21.061,8.6,21.146,8.6,21.268Zm-1.612-.239c-.036.106.067.229.223.261a.24.24,0,0,0,.321-.106c.031-.106-.067-.229-.223-.276A.263.263,0,0,0,6.988,21.029Zm2.291-.09c-.15.037-.254.138-.238.261.016.106.15.175.306.138s.254-.138.238-.245S9.429,20.922,9.279,20.938ZM12.69.563A12.592,12.592,0,0,0,0,13.537,13.311,13.311,0,0,0,8.787,26.256c.664.122.9-.3.9-.643,0-.33-.016-2.148-.016-3.265,0,0-3.629.8-4.391-1.585,0,0-.591-1.547-1.441-1.946,0,0-1.187-.835.083-.819a2.725,2.725,0,0,1,2,1.372A2.7,2.7,0,0,0,9.7,20.481a2.985,2.985,0,0,1,.829-1.792c-2.9-.33-5.822-.76-5.822-5.876A4.083,4.083,0,0,1,5.93,9.682a5.144,5.144,0,0,1,.135-3.61c1.083-.346,3.577,1.436,3.577,1.436a11.95,11.95,0,0,1,6.511,0S18.646,5.72,19.73,6.071a5.142,5.142,0,0,1,.135,3.61A4.182,4.182,0,0,1,21.2,12.814c0,5.131-3.053,5.541-5.951,5.876a3.188,3.188,0,0,1,.881,2.467c0,1.792-.016,4.009-.016,4.445,0,.346.238.766.9.643a13.192,13.192,0,0,0,8.7-12.708A12.867,12.867,0,0,0,12.69.563ZM5.039,18.9c-.067.053-.052.175.036.277.083.085.2.122.27.053.067-.053.052-.175-.036-.277C5.225,18.87,5.106,18.833,5.039,18.9Zm-.56-.431c-.036.069.016.154.119.207a.152.152,0,0,0,.223-.037c.036-.069-.016-.154-.119-.207C4.6,18.4,4.515,18.418,4.479,18.471Zm1.68,1.893c-.083.069-.052.229.067.33.119.122.27.138.337.053.067-.069.036-.229-.067-.33C6.381,20.295,6.226,20.279,6.158,20.364Zm-.591-.782c-.083.053-.083.191,0,.314s.223.175.29.122a.247.247,0,0,0,0-.33C5.785,19.567,5.65,19.513,5.567,19.582Z"
        transform="translate(0 -0.563)"
        fill="#292929"
      />
    </svg>
  );
};
export const TwitterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={style._twitter_icon}
      viewBox="0 0 25.712 22.111"
    >
      <path
        d="M23.069,8.891c.016.242.016.484.016.726,0,7.376-5.3,15.875-14.993,15.875A14.3,14.3,0,0,1,0,22.987a10.3,10.3,0,0,0,1.273.069,10.187,10.187,0,0,0,6.542-2.384A5.326,5.326,0,0,1,2.888,16.8a6.285,6.285,0,0,0,1,.086A5.282,5.282,0,0,0,5.27,16.7a5.511,5.511,0,0,1-4.226-5.476v-.069a5.087,5.087,0,0,0,2.382.708A5.668,5.668,0,0,1,1.077,7.216,5.788,5.788,0,0,1,1.795,4.4,14.722,14.722,0,0,0,12.66,10.239,6.655,6.655,0,0,1,12.53,8.96a5.429,5.429,0,0,1,5.27-5.58,5.126,5.126,0,0,1,3.85,1.762A10.032,10.032,0,0,0,24.994,3.8,5.519,5.519,0,0,1,22.678,6.87a10.106,10.106,0,0,0,3.035-.864,11.714,11.714,0,0,1-2.643,2.885Z"
        transform="translate(0 -3.381)"
        fill="#292929"
      />
    </svg>
  );
};
export const LinkedInIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23.821 23.821"
      className={style._linkedIn_icon}
    >
      <path
        d="M22.12,2.25H1.7A1.709,1.709,0,0,0,0,3.967V24.354a1.709,1.709,0,0,0,1.7,1.717H22.12a1.713,1.713,0,0,0,1.7-1.717V3.967A1.713,1.713,0,0,0,22.12,2.25ZM7.2,22.668H3.669V11.3H7.2V22.668ZM5.434,9.747A2.047,2.047,0,1,1,7.481,7.7,2.048,2.048,0,0,1,5.434,9.747Zm15,12.921H16.9v-5.53c0-1.319-.027-3.015-1.834-3.015-1.84,0-2.122,1.436-2.122,2.919v5.626H9.417V11.3H12.8v1.553h.048A3.719,3.719,0,0,1,16.2,11.018c3.573,0,4.238,2.356,4.238,5.418Z"
        transform="translate(0 -2.25)"
        fill="#292929"
      />
    </svg>
  );
};
export const FacebookIcon = () => {
  return (
    <svg
      className={style._facebook_icon}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23.821 23.821"
    >
      <path
        d="M21.269,2.25H2.552A2.552,2.552,0,0,0,0,4.8V23.519a2.552,2.552,0,0,0,2.552,2.552h7.3v-8.1H6.5V14.161H9.85V11.255c0-3.3,1.967-5.13,4.981-5.13a20.294,20.294,0,0,1,2.952.257V9.626H16.12a1.906,1.906,0,0,0-2.149,2.059v2.475h3.657l-.585,3.812H13.971v8.1h7.3a2.552,2.552,0,0,0,2.552-2.552V4.8A2.552,2.552,0,0,0,21.269,2.25Z"
        transform="translate(0 -2.25)"
        fill="#292929"
      />
    </svg>
  );
};
export const InstagramIcon = () => {
  return (
    <svg
      className={style._instagram_icon}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23.835 23.829"
    >
      <path
        d="M11.915,8.043a6.11,6.11,0,1,0,6.11,6.11A6.1,6.1,0,0,0,11.915,8.043Zm0,10.082a3.972,3.972,0,1,1,3.972-3.972A3.979,3.979,0,0,1,11.915,18.124ZM19.7,7.793a1.425,1.425,0,1,1-1.425-1.425A1.422,1.422,0,0,1,19.7,7.793Zm4.046,1.446a7.052,7.052,0,0,0-1.925-4.993,7.1,7.1,0,0,0-4.993-1.925C14.861,2.21,8.964,2.21,7,2.321A7.088,7.088,0,0,0,2,4.241,7.075,7.075,0,0,0,.078,9.234c-.112,1.967-.112,7.864,0,9.832A7.052,7.052,0,0,0,2,24.059,7.108,7.108,0,0,0,7,25.983c1.967.112,7.864.112,9.832,0a7.052,7.052,0,0,0,4.993-1.925,7.1,7.1,0,0,0,1.925-4.993c.112-1.967.112-7.859,0-9.826ZM21.2,21.177a4.021,4.021,0,0,1-2.265,2.265c-1.569.622-5.291.479-7.024.479s-5.461.138-7.024-.479a4.021,4.021,0,0,1-2.265-2.265c-.622-1.569-.479-5.291-.479-7.024s-.138-5.461.479-7.024A4.021,4.021,0,0,1,4.891,4.863c1.569-.622,5.291-.479,7.024-.479s5.461-.138,7.024.479A4.021,4.021,0,0,1,21.2,7.128c.622,1.569.479,5.291.479,7.024S21.826,19.613,21.2,21.177Z"
        transform="translate(0.005 -2.238)"
        fill="#292929"
      />
    </svg>
  );
};
